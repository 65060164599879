import mainNavStyles from '@components/molecules/main-nav/main-navigation.module.scss';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './tracer.module.scss';

export type TracerProps = {
  as?: string;
  selector: (container: HTMLDivElement) => NodeList;
  tracerProps?: React.HTMLProps<HTMLDivElement>;
} & React.HTMLProps<HTMLDivElement>;

type TracerPosition = {
  x: number;
  y: number;
  size: number | string;
};

const Tracer: React.FC<TracerProps> = (props) => {
  const {
    children,
    className,
    selector,
    as = 'div',
    tracerProps: { className: tracerClassName, style: tracerStyle, ...restTracerProps } = {},
    ...rest
  } = props;

  const Tag = as as unknown as React.ElementType;

  const container = useRef<HTMLDivElement>(null);
  const tracer = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<TracerPosition>({
    x: 0,
    y: 0,
    size: 0,
  });

  useEffect(() => {
    if (typeof window === 'undefined' || !container.current || !tracer.current) {
      return () => {};
    }

    const nodes = selector(container.current);

    const onMouseIn = (e: MouseEvent) => {
      const { offsetLeft, offsetTop, clientWidth, classList } = e.target as unknown as HTMLElement;
      // We need to dynamically calculate size of the element for the tracer to works correctly
      const size = classList.contains(mainNavStyles.desktop__noChildren)
        ? `calc(${clientWidth}px - 1.625rem)`
        : `calc(${clientWidth}px - 2.2rem)`;
      const tracerHeight = tracer.current.clientHeight;

      setPosition({
        x: offsetLeft,
        y: offsetTop - tracerHeight,
        size,
      });
    };

    const onMouseOut = () => {
      setPosition({ x: 0, y: 0, size: 0 });
    };

    nodes.forEach((node) => {
      node?.addEventListener('mouseenter', onMouseIn);
    });

    container.current.addEventListener('mouseleave', onMouseOut);

    return () => {
      nodes.forEach((node) => {
        node?.removeEventListener?.('mouseenter', onMouseIn);
      });

      container.current?.removeEventListener?.('mouseleave', onMouseOut);
    };
  }, [container, children, selector]);

  const tracerMergedStyles = useMemo(
    () => ({
      ...tracerStyle,
      ...{
        width: position.size,
        transform: `translate(${position.x}px, 0px)`,
      },
    }),
    [position, tracerStyle],
  );

  return (
    <Tag
      className={clsx(styles.container, className)}
      ref={container}
      {...rest}
    >
      <div
        className={clsx(styles.tracer, tracerClassName)}
        ref={tracer}
        style={tracerMergedStyles}
        {...restTracerProps}
      />
      {children}
    </Tag>
  );
};

export default Tracer;
