import { useEffect } from 'react';
import TagContainer from '@enums/tagContainer';
import eventsPayload from '@config/analytics';
import { useAnalytics } from '@context/analytics.context';
import { PageViewEvent, SnowPlowEventPayload } from '@interfaces/models/internal/analytics-event/product-event';

export type EventShape = {
  type: 'event';
  container: TagContainer;
  payload: SnowPlowEventPayload;
};

export const eventsCatalogPayload: Record<string, Record<string, Partial<EventShape['payload']>>> = {
  footer: {
    contact_us: {
      category: 'navigation',
      action: 'footer',
      label: '#mnemonic#',
    },
  },
  i18n_popup: {
    confirm_site_continue: {
      category: 'pop_up',
      action: 'continue',
      property: 'country_language_preferences',
    },
    confirm_site_change: {
      category: 'pop_up',
      action: 'change',
      property: 'country_language_preferences',
    },
    confirm_i18n_continue: {
      category: 'confirm_preferences',
      action: 'confirm',
      property: '#site_i18n#',
    },
    confirm_i18n_saveChange: {
      category: 'confirm_preferences',
      action: 'save',
      property: '#site_i18n#',
    },
    footer_i18n_click: {
      category: 'navigation',
      action: 'footer',
      label: 'country_language_preferences',
    },
  },
  product: {
    shopping_bag_delete: {
      category: 'shopping_bag',
      action: 'edit_bag',
      label: 'delete_item',
    },
    pdp_checkout_popin: {
      category: 'product',
      action: 'popin',
      label: 'proceed_to_checkout',
    },
    pdp_continue_shopping: {
      category: 'product',
      action: 'popin',
      label: 'continue_shopping',
    },
    pdp_add_to_bag: {
      category: 'product',
      action: 'bag',
      label: 'add_to_bag',
    },
    similar_products: {
      category: 'similar_products',
      action: 'cross-sell>similar>click',
      label: '#position#',
      property: '#product_id#',
    },
    click_detailed_fees: {
      category: 'fee_transparency',
      action: 'click_info_icon',
    },
    hover_detailed_fees: {
      category: 'fee_transparency',
      action: 'hover_info_icon',
    },
    // TODO: Old events (pdp_remove_favourites, pdp_add_favourites) need to be cleaned after testing the new one
    pdp_remove_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
      label: '#product_id#',
    },
    pdp_add_favourites: {
      category: 'like',
      action: 'add_to_favourites',
      label: '#product_id#',
    },
    // TODO: New event
    add_to_favourites: {
      category: 'like',
      action: 'add_to_favourites',
    },
    remove_from_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
    },
    product_impression: {
      product_feed_category: 'catalog',
    },
    monetization_show_buyer_fee: {
      category: 'monetization',
      action: 'show_buyer_fee',
      label: '#buyer_view# or #seller_view',
      property: '#Page name#',
    },
    monetization_buyer_fee_pop_in_close: {
      category: 'pop_in_monetization_buyer_fee',
      action: 'close',
      label: '#buyer_view# or #seller_view',
      property: '#page#',
      value: '#The % displayed inside the modal#',
    },
    monetization_show_seller_fee: {
      category: 'monetisation',
      action: 'show_seller_fee',
      label: 'seller_view',
      property: 'price_drop',
    },
    monetization_seller_fee_pop_in_close: {
      category: 'pop_in_monetization_seller_fee',
      action: 'close',
      property: 'price_drop',
      label: '',
    },
    report_item: {
      category: 'digital_service_act',
      action: 'report_item',
    },
  },
  navigation: {
    navigation_header: {
      category: 'navigation',
      action: 'header',
      label: '#button_name#',
      value: '#position#',
    },
    navigation_bottom_bar: {
      category: 'navigation',
      action: 'bottom_bar',
      label: '#button_name#',
      value: '#product_id#',
    },
    navigation_cart: {
      category: 'navigation',
      action: 'bag',
      label: '#proceed_to_checkout or view_bag#',
    },
    product_click: {},
    open_navigation_tab: {
      category: 'category_menu',
      action: 'open',
    },
    click_category_menu: {
      category: 'category_menu',
      action: 'click',
    },
    me_menu_click_entry: {
      category: 'navigation',
      action: 'me_menu',
      label: '#entry# : my_profile, get_paid, my_items, orders_sales, my_account, contact_us, help_center, log_out',
    },
  },
  favorites: {
    favourites_empty_state: {
      category: '#my_favourites or #member_favourites',
      action: 'empty_state',
      label: '#find_fresh_listings or #try_new_filters',
    },
    prd_sorting_change: {
      category: 'sorting_change',
      action: '#new_sorting#',
    },
    favorites_click_cta: {
      category: 'my_favourites',
      action: '#see_similar or #make_an_offer or #see_offer',
      label: 'cta',
    },
    favorites_add_to_bag_cta: {
      category: 'my_favourites',
      action: 'bag',
      label: '#add_to_bag_cta or #view_bag',
    },
    favorites_add_to_bag: {
      category: 'my_favourites',
      action: 'bag',
      label: 'add_to_bag',
    },
    favorites_filter_open: {
      category: '#my_favourites or #member_favourites',
      action: 'filter',
      label: 'open',
      property: '<brand/category/color/condition/material>',
    },
    favorites_filter_apply: {
      category: '#my_favourites or #member_favourites',
      action: 'filter',
      label: 'apply',
      property: '<brand/category/color/condition/material>',
    },
    favorites_filter_apply_success: {
      category: '#my_favourites or #member_favourites',
      action: 'filter',
      label: 'apply_success',
      property: '<brand/category/color/condition/material>',
    },
    // TODO: New event
    add_to_favourites: {
      category: 'like',
      action: 'add_to_favourites',
    },
    remove_from_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
    },
    // TODO: Old events (prd_remove_favourites, prd_add_favourites) need to be cleaned after testing the new one
    prd_remove_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
      product_id: '#product_id#',
      product_card_tag: '#tag_on_product_card#',
      product_feed_category: '#name_of_the_feed_category#',
      seller_id: '#seller_id#',
      product_universe: '#product_universe#',
      product_category: '#product_category#',
      product_sub_category: '#product_sub_category#',
      product_brand: '#product_brand#',
      product_unit_price_ati: '#product_unit_price_ati#',
      product_country: '#product_country#',
      product_position: '#product_position#',
      currency: '#currency#',
    },
    prd_add_favourites: {
      category: 'like',
      action: 'add_to_favourites',
      product_id: '#product_id#',
      seller_id: '#seller_id#',
      product_universe: '#product_universe#',
      product_category: '#product_category#',
      product_sub_category: '#product_sub_category#',
      product_brand: '#product_brand#',
      product_unit_price_ati: '#product_unit_price_ati#',
      product_country: '#product_country#',
      product_position: '#product_position#',
      currency: '#currency#',
      product_feed_category: '#name_of_the_feed_category#',
    },
  },
  recommendations: {
    recommendations_empty_state: {
      category: 'my_recommendations',
      action: 'empty_state',
      label: 'find_fresh_listings',
    },
    prd_sorting_change: {
      category: 'sorting_change',
      action: '#new_sorting#',
    },
  },
  mmao: {
    popinOpen: {
      popinName: 'MMAO',
    },
    mmao_buy_now: {
      eventCategory: 'mmao',
      eventAction: 'click_buy_now',
      eventLabel: 'negotiating_area_banner',
    },
    mmao_ongoing_banner: {
      eventCategory: 'mmao',
      eventAction: 'view_banner',
      eventLabel: 'negotiating_area_banner',
      eventValue: '#buyers_length#',
    },
    mmao_ongoing_banner_notification: {
      eventCategory: 'mmao',
      eventAction: 'view_banner_notification',
      eventLabel: 'negotiating_area_banner',
      eventValue: '#buyers_length#',
      eventProperty: '#product_id#',
    },
    mmao_ongoing_banner_pdp: {
      eventCategory: 'mmao',
      eventAction: 'view_banner_pdp',
      eventLabel: 'pdp_banner',
      eventValue: '#buyers_length#',
    },
    mmao_negotiating_area: {
      eventCategory: 'mmao',
      eventAction: 'view_negotiating_area',
      eventLabel: 'negotiating_area_pop_in',
    },
    pdp_make_an_offer: {
      eventCategory: 'mmao',
      eventAction: 'make_an_offer',
      eventLabel: '',
    },
    pdp_send_an_offer: {
      eventCategory: 'mmao',
      eventAction: 'send_an_offer',
      eventLabel: '',
    },
    mmao_duties: {
      eventCategory: 'mmao',
      eventAction: 'duties',
      eventLabel: 'base_price',
      eventValue: '#duties_taxes#',
      eventProperty: '#shipping_duties_taxes#',
    },
    mmao_duties_information: {
      eventCategory: 'mmao',
      eventAction: 'duties_info',
      eventValue: '#duties_taxes#',
    },
    mmao_fpd_notification: {
      eventCategory: 'mmao_fpd',
      eventAction: 'notification_click',
      eventValue: '#product_id#',
      eventProperty: '#negotiation_id#',
    },
    mmao_fpd_popin_view: {
      eventCategory: 'mmao_fpd',
      eventAction: 'view_popin',
      eventValue: '#product_id#',
      eventProperty: '#negotiation_id#',
    },
    mmao_fpd_opt_in: {
      eventCategory: 'mmao_fpd',
      eventAction: 'opt_in',
      eventValue: '#product_id#',
      eventProperty: '#negotiation_id#',
    },
    mmao_fpd_opt_out: {
      eventCategory: 'mmao_fpd',
      eventAction: 'opt_out',
      eventValue: '#product_id#',
      eventProperty: '#negotiation_id#',
    },
    mmao_fpd_opt_in_confirm: {
      eventCategory: 'mmao_fpd',
      eventAction: 'opt_in_confirm',
      eventValue: '#product_id#',
      eventProperty: '#negotiation_id#',
    },
    mmao_fpd_opt_in_reject: {
      eventCategory: 'mmao_fpd',
      eventAction: 'opt_in_reject',
      eventValue: '#product_id#',
      eventProperty: '#negotiation_id#',
    },
    mmao_fpd_learn_more: {
      eventCategory: 'mmao_fpd',
      eventAction: 'learn_more',
      eventValue: '#product_id#',
      eventProperty: '#negotiation_id#',
    },
    mmao_fpd_discover: {
      eventCategory: 'mmao_fpd',
      eventAction: 'discover',
      eventValue: '#product_id#',
      eventProperty: '#negotiation_id#',
    },
    mmao_add_to_bag: {
      category: 'mmao',
      action: 'bag',
      label: 'add_to_bag',
    },
    mmao_send_an_offer_error: {
      category: 'mmao',
      action: 'error',
    },
  },
  catalog_management: {
    catalog_management_profile: {
      category: 'catalog_management',
      action: 'profile',
    },
    catalog_management_add_photo: {
      category: 'catalog_management',
      action: 'add_pictures_cta',
      label: '#product_id#',
    },
    catalog_management_drop_price: {
      category: 'catalog_management',
      action: 'drop_price_cta',
      label: '#product_id#',
    },
    catalog_management_remove_sale: {
      category: 'catalog_management',
      action: 'remove_from_sale_cta',
      label: '#product_id#',
    },
    catalog_management_upload_picture: {
      category: 'catalog_management',
      action: 'upload_picture',
      label: '#product_id#',
      value: '#picture_count#',
    },
    catalog_management_submit_picture: {
      category: 'catalog_management',
      action: 'submit_picture',
      label: '#product_id#',
      value: '#uploaded_picture_count#',
    },
    catalog_management_submit_new_price: {
      category: 'catalog_management',
      action: 'drop_price',
      label: '#product_id#',
      value: '#new_price#',
      property: '#old_price#',
    },
    catalog_management_confirm_remove_sale: {
      category: 'catalog_management',
      action: 'confirm_remove',
      label: '#product_id#',
      property: '#reason#',
    },
    catalog_management_export: {
      category: 'catalog_management',
      action: 'export',
    },
    catalog_management_tab: {
      category: 'catalog_management',
      action: '#tab_name#',
    },
  },
  remove_from_sale: {
    remove_from_sale_confirm_reason: {
      category: 'remove_from_sale',
      action: 'confirm_reason',
      label: '#selected_reason#',
      property: '#text_by_user#',
      product_id: '#product_id',
      seller_id: '#seller_id',
      product_universe: '#universe',
      product_category: '#category',
      product_subcategory: '#subcategory',
      product_brand: '#brand',
      product_unit_price: '#price of product',
      product_country: '#country of product',
      product_currency: '#local currency',
    },
    remove_from_sale_close_pop_in: {
      category: 'pop_in_remove_from_sale',
      action: 'close',
    },
    remove_from_sale_cancel: {
      category: 'remove_from_sale',
      action: 'cancel_removal',
    },
    remove_from_sale_confirm_removal: {
      category: 'removal_from_sale',
      action: 'confirm_removal',
    },
    remove_from_sale_holiday_mode: {
      category: 'remove_from_sale',
      action: 'go_to_holiday_mode',
    },
    remove_from_sale_sell_an_item: {
      category: 'remove_from_sale',
      action: 'go_to_sell_an_item',
    },
  },
  cancel_my_sale: {
    cancel_my_sale_confirm_reason: {
      category: 'cancel_my_sale',
      action: 'confirm_reason',
      label: '#selected_reason#',
      property: '#text_by_user#',
    },
    cancel_my_sale_close_pop_in: {
      category: 'pop_in_cancel_my_sale',
      action: 'close',
    },
    cancel_my_sale_cancel: {
      category: 'cancel_my_sale',
      action: 'cancel_cancellation',
    },
    cancel_my_sale_confirm_removal: {
      category: 'cancel_my_sale',
      action: 'confirm_cancellation',
    },
    cancel_my_sale_sell_an_item: {
      category: 'cancel_my_sale',
      action: 'go_to_sell_an_item',
    },
  },
  chat: {
    bs_chat: {},
    pdp_start_chat: {
      eventCategory: 'chat',
      eventAction: 'start_chat',
    },
    product_impression: {},
    product_click: {},
    bs_chat_change_conversation: {
      category: 'chat',
      action: 'change_conversation',
    },
    bs_chat_buy_now: {
      category: 'chat',
      action: 'bag',
      label: 'add_to_bag',
    },
    bs_chat_make_offer: {
      category: 'chat',
      action: 'make_an_offer',
    },
    bs_chat_popin: {
      category: 'chat',
      action: 'popin',
      label: '#option_choosen#',
    },
    bs_chat_see_offer: {
      category: 'chat',
      action: 'see_offers',
    },
    bs_chat_report_conversation: {
      category: 'chat',
      action: 'report_conversation',
    },
    bs_chat_see_privacy: {
      category: 'chat',
      action: 'see_privacy_policy',
    },
    bs_chat_see_terms: {
      category: 'chat',
      action: 'see_terms',
    },
    bs_chat_block_user: {
      category: 'chat',
      action: 'block_user',
    },
    bs_chat_help_center: {
      category: 'chat',
      action: 'help_center',
    },
    members_profile_click: {
      category: 'members',
      action: 'access_profile',
    },
    bs_chat_send_message: {
      category: 'chat',
      action: 'send_message',
    },
    bs_chat_view_bag: {
      category: 'bag',
      action: 'view_bag',
    },
    bs_chat_view: {
      category: 'chat',
      action: 'view_chat_pop_in',
    },
  },
  cms_component: {
    cms_content_click: {
      event: 'cms_content_click',
    },
    cms_content_view: {
      event: 'cms_content_view',
    },
    popin_voucher_info: {},
  },
  alerts: {
    alert_create: {
      category: 'alert',
      action: 'save_my_alert',
    },
    alert_manage: {
      category: 'alert',
      action: 'manage',
    },
    alert_view_all_items: {
      category: 'alert',
      action: 'matching_items',
      label: 'view_all',
      nb_items: '#nb_new_items#',
    },
    alert_delete: {
      category: 'alert',
      action: 'delete',
    },
    alert_activate_notifications: {
      category: 'alert',
      action: 'notifications',
      label: 'activate',
    },
    alert_deactivate_notifications: {
      category: 'alert',
      action: 'notifications',
      label: 'deactivate',
    },
    // TODO: New event
    add_to_favourites: {
      category: 'like',
      action: 'add_to_favourites',
    },
    remove_from_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
    },
    // TODO: Old events (prd_remove_favourites, prd_add_favourites) need to be cleaned after testing the new one
    prd_remove_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
      product_id: '#product_id#',
      product_card_tag: '#tag_on_product_card#',
      product_feed_category: '#name_of_the_alert#',
      product_unit_price_ati: '#product_unit_price_ati#',
      product_position: '#product_position#',
      currency: '#currency#',
    },
    prd_add_favourites: {
      category: 'like',
      action: 'add_to_favourites',
      product_id: '#product_id#',
      product_card_tag: '#tag_on_product_card',
      product_feed_category: '#name_of_the_alert#',
      product_unit_price_ati: '#product_unit_price_ati#',
      product_position: '#product_position#',
      currency: '#currency#',
    },
  },
  my_preferences: {
    preferences_enabled: {
      eventCategory: 'my_preferences',
      eventAction: 'regular_filters',
      eventLabel: 'enabled',
    },
    onboarding_preferences_open: {
      eventCategory: 'my_preferences',
      eventAction: 'onboarding',
      eventLabel: 'open',
    },
    edit_preferences_open: {
      eventCategory: 'my_preferences',
      eventAction: 'edit_perso',
      eventLabel: 'open',
    },
    edit_preferences_confirmation: {
      eventCategory: 'my_preferences',
      eventAction: 'edit_perso',
      eventLabel: 'save',
    },
  },
  smart_banner: {
    banner_app_download_close: {
      category: 'banner',
      action: 'close',
      label: 'app_download',
    },
    banner_app_download_open: {
      category: 'banner',
      action: 'open_app',
      label: 'app_download',
    },
    banner_app_download_view: {
      category: 'banner',
      action: 'view',
      label: 'app_download',
    },
  },
  pdp_top_banner: {
    welcome_offer_close: {
      category: 'banner',
      action: 'close',
      label: '#code_promo#',
    },
    welcome_offer_view: {
      category: 'banner',
      action: 'view',
      label: '#code_promo#',
    },
  },
  top_navigation: {
    top_navigation_view: {
      category: 'navigation',
      action: 'view',
      label: 'top_navigation_block',
    },
    top_navigation_click: {
      category: 'navigation',
      action: 'click',
      label: 'top_navigation_block',
    },
  },
  usp_block: {},
  checkout: {},
  cms_page: {},
  notification: {
    notification_impression: {
      notification_context: {
        notification_id: '#id_push#',
        notification_type: '#mnemonic#',
        notification_screen_section: '#filter#',
        notification_read_status: '#read_status#',
        notification_position: '#index#',
      },
    },
    click_notification: {
      category: 'notification_center',
      action: 'click',
      label: '#filter#',
      property: '#id_push#_#mnemonic#',
      value: '#read_status#',
      notification_id: '#id_push#',
      notification_type: '#mnemonic#',
      notification_screen_section: '#filter#',
      notification_read_status: '#read_status#',
      notification_position: '#index#',
    },
    view_notification: {
      category: 'notification_center',
      action: 'view',
      label: '#filter#',
      property: '#id_push#_#mnemonic#',
      value: '#read_status#',
    },
    filter_notification: {
      notificationFilterCategory: '#filter#',
      notificationFilterStatus: '#read_status#',
      category: 'notification_center',
      action: 'filter_click',
      label: '#filter#',
      value: '#read_status#',
    },
  },
  search_modal: {
    search_autocomplete_last_search: {
      category: 'search',
      action: 'autocomplete_last_search',
    },
    search_autocomplete_last_category: {
      category: 'search',
      action: 'autocomplete_last_category',
    },
    search_autocomplete_suggestion_keyword: {
      category: 'search',
      action: 'autocomplete_suggestion_keyword',
    },
    delete_last_search: {
      category: 'search',
      action: 'delete_last_search',
    },

    search_autocomplete_popular_search: {
      category: 'search',
      action: 'autocomplete_popular_search',
    },
    search_autocomplete_suggestion_brand: {
      category: 'search',
      action: 'autocomplete_suggestion_brand',
    },
    open_search: {
      category: 'search_bar',
      action: 'open',
    },
    search_manual: {
      action: 'manual',
      category: 'search',
    },
  },
  catalog: {
    did_you_mean_view: {
      category: 'did_you_mean',
      action: 'view_suggestion',
    },
    did_you_mean_click: {
      category: 'did_you_mean',
      action: 'click_suggestion',
    },
    sorting_change: {
      category: 'sorting_change',
    },
    add_to_favourites: {
      category: 'like',
      action: 'add_to_favourites',
    },
    remove_from_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
    },
    apply_filter: {
      category: 'filter',
      action: 'apply',
      label: 'regular_filter',
    },
    app_breaker_view: {
      category: 'view',
      action: 'page_section',
    },
    app_breaker_availability: {
      category: 'app_breaker',
      action: 'eligible_plp',
    },
  },
  similar_products: {
    pdp_reco_view_all: {
      category: 'pdp_recirculation',
      action: 'view_all',
    },
  },
  hero_pdp: {
    hero_pdp_photo_zoom: {
      category: 'product',
      action: 'photo_zoom',
    },
    hero_pdp_recirculation: {
      category: 'hero_pdp_recirculation',
      action: 'discover_more',
    },
    hero_pdp_view_details: {
      category: 'product',
      action: 'view_details',
    },
  },
  orders_sales_timeline: {
    my_account_timeline: {},
    timeline_orders: {
      category: 'timeline',
      action: 'orders',
    },
    timeline_sales: {
      category: 'timeline',
      action: 'sales',
    },
    timeline_load_more: {
      category: 'timeline',
      action: 'load_more',
      label: '#orders# or #sales#',
    },
    timeline_learn_more: {
      category: 'timeline',
      action: 'learn_more',
      label: '#orders# or #sales#',
    },
    timeline_download_income_summary: {
      category: 'timeline',
      action: 'download_income_summary',
      label: '#orders# or #sales#',
      property: '#Selected dropdown option#',
    },
    timeline_product_click: {
      category: 'timeline',
      action: 'select_product',
      label: '#orders# or #sales#',
    },
    timeline_help_center: {
      category: 'timeline',
      action: 'help',
      label: '#buyer# or #seller#',
    },
    timeline_download_label: {
      category: 'timeline',
      action: 'download_label',
      label: '#buyer# or #seller#',
    },
    timeline_cancel_sale: {
      category: 'timeline',
      action: 'cancel_sale',
      label: '#buyer# or #seller#',
    },
    timeline_tracking_sendin: {
      category: 'timeline',
      action: 'tracking_sendin',
      label: '#buyer# or #seller#',
    },
    timeline_tracking_sendout: {
      category: 'timeline',
      action: 'tracking_sendout',
      label: '#buyer# or #seller#',
    },
    timeline_tracking_send_direct: {
      category: 'timeline',
      action: 'tracking_send_direct',
      label: '#buyer# or #seller#',
    },
    timeline_relist_item: {
      category: 'timeline',
      action: 'relist_item',
      label: '#buyer# or #seller#',
    },
    timeline_report_issue: {
      category: 'timeline',
      action: 'report_issue',
      label: '#buyer# or #seller#',
    },
    timeline_view_issue: {
      category: 'timeline',
      action: 'view_issue',
      label: 'buyer',
      product_id: '#product_id#',
    },
    timeline_view_report: {
      category: 'timeline',
      action: 'view_report',
      label: '#buyer# or #seller#',
    },
    timeline_view_auth_qc_report: {
      category: 'timeline',
      action: 'view_report',
      label: '#buyer# or #seller#',
    },
    timeline_item_assessment_nego: {
      category: 'timeline',
      action: 'item_assessment_nego',
      label: '#buyer# or #seller#',
    },
    timeline_download_label_return: {
      category: 'timeline',
      action: 'download_label_return',
      label: '#buyer# or #seller#',
    },
    timeline_b2c_return: {
      category: 'timeline',
      action: 'b2c_return_form',
      label: '#buyer# or #seller#',
    },
    timeline_b2c_return_submit: {
      category: 'timeline',
      action: 'b2c_return_form_submit',
      label: '#buyer# or #seller#',
    },
    timeline_b2c_return_cancel: {
      category: 'timeline',
      action: 'b2c_return_form_cancel',
      label: '#buyer# or #seller#',
    },
    timeline_invoice: {
      category: 'timeline',
      action: 'download_invoice',
      label: '#buyer# or #seller#',
    },
    timeline_b2c_seller_invoice: {
      category: 'timeline',
      action: 'download_invoice_b2c',
      label: '#buyer# or #seller#',
    },
    timeline_pickup_confirmation: {
      category: 'timeline',
      action: 'item_pickup_confirm',
      label: '#buyer# or #seller#',
    },
    my_account_send_products: {
      category: 'timeline',
      action: 'reminder_send_items',
      label: 'seller',
    },
    my_account_cancel_sale: {
      category: 'timeline',
      action: 'reminder_cancel_sale',
      label: 'seller',
    },
    my_account_download_label: {
      category: 'timeline',
      action: 'reminder_download_label',
      label: 'seller',
    },
    my_account_item_shipped: {
      category: 'timeline',
      action: 'reminder_item_shipped',
      label: 'seller',
    },
    timeline_pickup_hours: {
      category: 'timeline',
      action: 'item_pickup_hours',
      label: '#buyer# or #seller#',
    },
    timeline_pickup_popin_confirm: {
      category: 'timeline',
      action: 'item_pickup_popin_confirm',
      label: '#buyer# or #seller#',
    },
    timeline_pickup_popin_cancel: {
      category: 'timeline',
      action: 'item_pickup_popin_cancel',
      label: '#buyer# or #seller#',
    },
    timeline_home_delivery_confirmation: {
      category: 'timeline',
      action: 'home_delivery_confirm',
      label: '#buyer# or #seller#',
    },
    timeline_home_delivery_popin_confirm: {
      category: 'timeline',
      action: 'home_delivery_popin_confirm',
      label: '#buyer# or #seller#',
    },
    timeline_home_delivery_popin_cancel: {
      category: 'timeline',
      action: 'home_delivery_popin_cancel',
      label: '#buyer# or #seller#',
    },
    timeline_callback: {
      category: 'timeline',
      action: 'callback',
      label: '#status#',
      property: '#error#',
      value: '#enabled#',
    },
    callback_forbidden_number: {
      category: 'callback',
      action: 'forbidden_number',
      label: '#prefix#',
    },
    callback_submit: {
      category: 'callback',
      action: 'submit',
    },
    callback_submission_success: {
      category: 'callback',
      action: 'submission_success',
    },
    callback_unavailable: {
      category: 'callback',
      action: 'unavailable',
    },
    callback_scheduled: {
      category: 'callback',
      action: 'scheduled',
    },
    callback_outside_working_hour: {
      category: 'callback',
      action: 'outside_working_hour',
    },
    timeline_search: {
      category: 'timeline',
      action: 'search',
      label: 'seller',
      property: '#search_keyword#',
      value: '#number_of_results#',
    },
    timeline_get_paid: {
      category: 'timeline',
      action: 'get_paid',
      label: 'seller',
      property: '#CTA_location#',
      product_id: '#product_id#',
    },
    timeline_seller_profile: {
      category: 'members',
      action: 'access_profile',
      label: 'NULL',
      property: '#id_seller#',
      product_id: '#product_id#',
    },
    timeline_bs_chat: {
      category: 'timeline',
      action: 'start_chat',
      label: '#buyer# or #seller#',
    },
  },
  order_report_issue: {
    report_issue_return_form_item_receipt: {
      category: 'return_form',
      action: '#action#_receipt',
      product_id: '#product_id#',
    },
    report_issue_return_form_select_reason: {
      category: 'return_form',
      action: 'select_reason',
      label: '#issue#',
      product_id: '#product_id#',
    },
    report_issue_return_form_submit: {
      category: 'return_form',
      action: 'submit',
      label: '#issue#',
      property: '#property#_receipt',
      value: '#nb_of_photos#',
      product_id: '#product_id#',
    },
    report_issue_return_form_photo: {
      category: 'return_form',
      action: 'photos',
      label: 'add_photo',
      property: '#contact_reason#',
    },
    report_issue_need_more_help_help_center: {
      category: 'report_issue',
      action: 'need_more_help',
      label: 'help_center',
      property: '#ticket_id#',
      product_id: '#product_id#',
    },
    report_issue_return_form_submission_success: {
      category: 'return_form',
      action: 'submission_success',
      property: '#ticket_id#',
      product_id: '#product_id#',
    },
    report_issue_need_more_help_screen: {
      category: 'report_issue',
      action: 'need_more_help',
      label: 'screen',
      property: '#ticket_id#',
      product_id: '#product_id#',
    },
  },
  seller_info: {
    seller_tax_submit: {
      category: 'seller_info',
      action: 'tax_form',
      label: 'submit',
    },
    seller_tax_submit_result: {
      category: 'seller_info',
      action: 'tax_form',
      label: '#submit_result#',
    },
    seller_tax_edit: {
      category: 'seller_info',
      action: 'tax_form',
      label: 'edit',
    },
    seller_tax_error_close: {
      category: 'seller_info',
      action: 'tax_form',
      label: 'error_pop_in_close',
    },
    seller_tax_error: {
      category: 'seller_info',
      action: 'tax_form',
      label: 'error_pop_in',
    },
    seller_tax_page: {
      category: 'seller_info',
      action: 'tax_form',
      label: '#page_type#',
    },
    seller_tax_error_reload: {
      category: 'seller_info',
      action: 'tax_form',
      label: 'error_page_reload',
    },
    seller_tax_info_tin: {
      category: 'seller_info',
      action: 'tax_form',
      label: 'info_icon_tin',
    },
  },
  profile_page: {
    profile__badge: {
      category: 'profile_page',
      action: 'clik_on_badge',
      label: '#badge_name#',
    },
    profile_click_tab: {
      category: 'profile',
      action: 'click_tab',
      label: '#tab_name#',
    },
    // TODO: New event
    add_to_favourites: {
      category: 'like',
      action: 'add_to_favourites',
    },
    remove_from_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
    },
    // TODO: Old events (prd_remove_favourites, prd_add_favourites) need to be cleaned after testing the new one
    prd_remove_favourites: {
      category: 'like',
      action: 'remove_from_favourites',
      product_id: '#product_id#',
      product_card_tag: '#tag_on_product_card#',
      product_feed_category: '#name_of_the_feed_category#',
      seller_id: '#seller_id#',
      product_universe: '#product_universe#',
      product_category: '#product_category#',
      product_sub_category: '#product_sub_category#',
      product_brand: '#product_brand#',
      product_unit_price_ati: '#product_unit_price_ati#',
      product_country: '#product_country#',
      product_position: '#product_position#',
      currency: '#currency#',
    },
    prd_add_favourites: {
      category: 'like',
      action: 'add_to_favourites',
      product_id: '#product_id#',
      seller_id: '#seller_id#',
      product_universe: '#product_universe#',
      product_category: '#product_category#',
      product_sub_category: '#product_sub_category#',
      product_brand: '#product_brand#',
      product_unit_price_ati: '#product_unit_price_ati#',
      product_country: '#product_country#',
      product_position: '#product_position#',
      currency: '#currency#',
      product_feed_category: '#name_of_the_feed_category#',
    },
    access_seller_profile: {
      category: 'members',
      action: 'access_profile',
      property: '#member_id#',
    },
    follow_profile: {
      category: 'members',
      label: '#member_id#',
    },
    report_user: {
      category: 'digital_service_act',
      action: 'report_user',
      label: '#user_id# of reported user',
    },
  },
  get_paid_page: {
    get_paid_submit: {
      category: 'get_paid',
      action: 'personal_info',
      label: 'submit',
    },
    get_paid_edit_personal_information: {
      category: 'get_paid',
      action: 'personal_info',
      label: 'edit',
    },
    get_paid_submit_error: {
      category: 'get_paid',
      action: 'personal_info',
      label: 'submit_error',
      property: '#error#',
    },
    get_paid_save_country: {
      category: 'get_paid',
      action: 'transfer_method',
      label: 'save_country',
      property: '#country#',
    },
    get_paid_popin: {
      category: 'pop_in_get_paid',
      action: 'open',
      label: 'verification_code',
    },
    get_paid_edit: {
      category: 'get_paid',
      action: 'transfer_method',
      label: 'edit',
    },
    get_paid_change_payment_method: {
      category: 'get_paid',
      action: 'transfer_method',
      label: 'change_payment_method',
    },
  },
  change_email_address_page: {
    send_email_change: {
      category: 'account_info',
      action: 'email_change',
      label: 'send_link',
    },
    send_email_change_success: {
      category: 'account_info',
      action: 'email_change',
      label: 'send_link_success',
    },
    send_email_change_error: {
      category: 'account_info',
      action: 'email_change',
      label: 'send_link_#error_name#',
    },
    contact_support_email_change: {
      category: 'account_info',
      action: 'email_change',
      label: 'contact_us',
    },
    confirm_new_email_change: {
      category: 'account_info',
      action: 'email_change',
      label: 'confirm_new_email',
    },
    email_change_success: {
      category: 'account_info',
      action: 'email_change',
      label: 'success',
    },
    info_needed_view: {
      category: 'pop_in_email_change',
      action: 'view',
      label: 'contact_us',
    },
    info_needed: {
      category: 'pop_in_email_change',
      action: 'contact_us_cta',
      label: 'contact_us',
    },
  },
  user_profile_menu: {
    site_preference_click: {
      category: 'navigation',
      action: 'me_menu',
      label: 'country_language_preferences',
    },
  },
  auth_qc_report: {
    auth_qc_report_download_report: {
      category: 'auth_qc_report',
      action: 'download',
    },
    auth_qc_report_collapse_authentication: {
      category: 'auth_qc_report',
      action: 'collapse',
      label: 'authentication',
    },
    auth_qc_report_expand_authentication: {
      category: 'auth_qc_report',
      action: 'expand',
      label: 'authentication',
    },
    auth_qc_report_expand_quality_control: {
      category: 'auth_qc_report',
      action: 'expand',
      label: 'qc',
    },
    auth_qc_report_collapse_quality_control: {
      category: 'auth_qc_report',
      action: 'collapse',
      label: 'qc',
    },
    auth_qc_report_learn_more_authentication: {
      category: 'auth_qc_report',
      action: 'learn_more',
      label: 'authentication',
    },
    auth_qc_report_learn_more_quality_control: {
      category: 'auth_qc_report',
      action: 'learn_more',
      label: 'qc',
    },
    auth_qc_report_catalog_rules_quality_control: {
      category: 'auth_qc_report',
      action: 'catalog_rules',
      label: 'qc',
    },
    auth_qc_report_help_center: {
      category: 'auth_qc_report',
      action: 'help_center',
    },
    auth_qc_report_learn_more: {
      category: 'auth_qc_report',
      action: 'learn_more',
    },
    auth_qc_report_check_discrepancies: {
      category: 'auth_qc_report',
      action: 'check_discrepancies',
    },
    auth_qc_report_qc_view_defect: {
      category: 'auth_qc_report',
      action: 'view_defect',
      label: 'qc',
    },
    auth_qc_report_highlights_view_highlight_image: {
      category: 'auth_qc_report',
      action: 'photo_view',
      label: 'highlights',
    },
    auth_qc_report_highlights_see_original: {
      category: 'auth_qc_report',
      action: 'see_original',
      label: 'highlights',
    },
    auth_qc_report_highlights_see_translation: {
      category: 'auth_qc_report',
      action: 'see_translation',
      label: 'highlights',
    },
    auth_qc_report_reject: {
      category: 'auth_qc_report',
      action: 'reject',
    },
    auth_qc_report_accept: {
      category: 'auth_qc_report',
      action: 'accept',
    },
    auth_qc_report_relist: {
      category: 'auth_qc_report',
      action: 'relist',
    },
    auth_qc_report_request_return: {
      category: 'auth_qc_report',
      action: 'request_return',
    },
    auth_qc_report_pop_up_ok: {
      category: 'auth_qc_report_pop_up_ok',
      action: 'confirm',
      label: '#relist OR #request_return OR #reject',
    },
    auth_qc_report_pop_up_cancel: {
      category: 'auth_qc_report_pop_up_cancel',
      action: 'not_now',
      label: '#relist OR #request_return OR #reject',
    },
    auth_qc_report_view: {
      category: 'auth_qc_report',
      action: 'view',
    },
  },
  delete_account: {
    account_deletion_faq: {
      category: 'account',
      action: 'deletion',
      label: 'click_faq',
    },
    account_deletion_contact_us: {
      category: 'account',
      action: 'deletion',
      label: 'contact_us',
    },
    account_deletion_confirm: {
      category: 'account',
      action: 'deletion',
      label: 'confirm',
    },
    account_deletion_success: {
      category: 'account',
      action: 'deletion',
      label: 'success',
    },
  },
  ...eventsPayload,
};

const useAnalyticEvents = (category: string) => {
  const { updateDataLayer, sendEvent, scriptsEventData } = useAnalytics();

  // TODO: You don't need this useEffect to check at runtime, categories should by strictly typed instead
  useEffect(() => {
    if (!eventsCatalogPayload[category]) {
      throw new Error('invalid category');
    }
  }, []);

  const sendAnalyticEvent = (value: string, data?: Partial<SnowPlowEventPayload>) => {
    const selectedEvent = eventsCatalogPayload[category][value];

    let payload = {
      event: value,
      ...data,
    };

    if (selectedEvent) {
      payload = { ...selectedEvent, ...payload };
    }

    sendEvent({
      type: 'event',
      container: TagContainer.Analytics,
      payload,
    });
  };

  const sendPageViewAnalyticEvents = (value: PageViewEvent) => {
    sendEvent({
      type: 'pageview',
      container: TagContainer.Analytics,
      payload: {
        event: 'pageview',
        ...value,
      },
    });
  };

  return {
    sendPageViewAnalyticEvents,
    sendAnalyticEvent,
    updateDataLayer,
    scriptsEventData,
  };
};

export default useAnalyticEvents;
