import Environment from '@config/index';
import { ApiResponse } from '@interfaces/api';
import { NotificationFeedCounterResponseV2 } from '@interfaces/api/responses/notification-feed-counter-response';
import { Notification, NotificationTab } from '@interfaces/models/notification';
import { axios, getAxiosConfig } from '@config/axios';

const BASE_URL = Environment.apiBaseUrl;

// TODO: Will be removed when notification V2 iteration is done.
export const getActivityNotifications = async (): Promise<Notification[]> => {
  const axiosConfig = getAxiosConfig();
  const {
    data: { data },
  } = await axios.get<ApiResponse<Notification[]>>(
    `${BASE_URL}/notification-feed?offset=0&limit=60&exclude=messages`,
    axiosConfig,
  );
  return data;
};

// TODO: Will be removed when notification V2 iteration is done.
export const getMessageNotifications = async (): Promise<Notification[]> => {
  const axiosConfig = getAxiosConfig();
  const {
    data: { data },
  } = await axios.get<ApiResponse<Notification[]>>(
    `${BASE_URL}/notification-feed?offset=0&limit=60&category=messages`,
    axiosConfig,
  );
  return data;
};

export const getNotificationCountV2 = async (): Promise<NotificationFeedCounterResponseV2['unseen']> => {
  const axiosConfig = getAxiosConfig();
  const {
    data: {
      data: { unseen },
    },
  } = await axios.get<ApiResponse<NotificationFeedCounterResponseV2>>(
    `${BASE_URL}/notification-feed-counter/v2`,
    axiosConfig,
  );
  return unseen;
};

export const getNotificationsByPaginationV2 = async (
  offset: number,
  limit: number,
  category: NotificationTab,
): Promise<ApiResponse<Notification[]>> => {
  const axiosConfig = getAxiosConfig();
  const { data } = await axios.get<ApiResponse<Notification[]>>(
    `${BASE_URL}/notification-feed/v2?offset=${offset}&limit=${limit}&category=${category}`,
    axiosConfig,
  );
  return data;
};

// TODO: Will be removed when notification V2 iteration is done.
export const getMessagesByPagination = async (offset: number, limit: number): Promise<ApiResponse<Notification[]>> => {
  const axiosConfig = getAxiosConfig();
  const { data } = await axios.get<ApiResponse<Notification[]>>(
    `${BASE_URL}/notification-feed?offset=${offset}&limit=${limit}&category=messages`,
    axiosConfig,
  );
  return data;
};

export const readNotification = async (notificationId: Notification['id']): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  await axios.patch(`${BASE_URL}/notification-feed/${notificationId}`, { status: 'read' }, axiosConfig);
};

export const resetNotificationsFeedCountersV2 = async (): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  await axios.patch(`${BASE_URL}/notification-feed/reset-counters`, { category: ['updates'] }, axiosConfig);
};
