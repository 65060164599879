import React, { useEffect, useRef, useState, JSX } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Button from '@components/atoms/button/button';
import useOnClickOutside from '@hooks/browser-events/use-on-click-outside';
import Icon from '../icon/icon';
import styles from './searchable-dropdown.module.scss';

export type SearchableDropdownOptionType = {
  id: string;
  name: string;
};

type SearchableDropdownProps = {
  name: string;
  options: SearchableDropdownOptionType[];
  inputPlaceholder?: string;
  initialVal: string | null;
  allowSearch?: boolean;
  renderOption?: (option, searchVal) => JSX.Element;
  onChangeOption: (option: SearchableDropdownOptionType) => void;
  selectedValProps?: {
    imgNode: (option: SearchableDropdownOptionType) => JSX.Element;
  };
  allowEmptyValue?: boolean;
  ariaLabel?: string;
};

export const OptionWithHighlight = ({ optionName, searchTerm }) => {
  if (!optionName) {
    return null;
  }
  const searchTermStartIndex = optionName.toLowerCase().indexOf(searchTerm.toLowerCase());
  if (!searchTerm || searchTermStartIndex <= -1) {
    return <>{optionName}</>;
  }
  return (
    <div className={styles['dropdown__optionsList__item--content']}>
      <pre>{optionName.substring(0, searchTermStartIndex)}</pre>
      <pre className={styles['dropdown__optionsList__item--strong']}>
        {optionName.substring(searchTermStartIndex, searchTermStartIndex + searchTerm.length)}
      </pre>
      <pre>{optionName.substring(searchTermStartIndex + searchTerm.length)}</pre>
    </div>
  );
};

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  name,
  inputPlaceholder,
  initialVal,
  allowSearch = true,
  renderOption,
  selectedValProps,
  onChangeOption,
  allowEmptyValue,
  ariaLabel,
}) => {
  const { t } = useTranslation();
  const searchablDropdownRef = useRef<HTMLDivElement>(null);
  const initialSelect = allowEmptyValue
    ? ((options.find((opt) => opt.id === initialVal) || {}) as SearchableDropdownOptionType)
    : options.find((opt) => opt.id === initialVal) || options[0];

  const searchOptionsElem = useRef<HTMLUListElement>(null);
  const searchInputElem = useRef<HTMLInputElement>(null);
  const [searchInputVal, setSearchInputVal] = useState<SearchableDropdownOptionType['name']>(initialSelect.name || '');
  const [isSearchInputFocused, setFocusSearchInput] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<SearchableDropdownOptionType>(initialSelect);
  const [optionsListIsOpen, setOptionsListIsOpen] = useState<boolean>(false);
  const filteredOptions =
    allowSearch && isSearchInputFocused
      ? (options || []).filter((option) =>
          (option?.name || '').toLowerCase().includes((searchInputVal || '').toLowerCase()),
        )
      : options;

  const handleCloseDropdown = () => {
    const classList: DOMTokenList = searchOptionsElem.current?.classList;
    classList?.add('vc-d-none');
  };

  const handleSelectOption = (option) => {
    if (!option) {
      return;
    }
    setSelectedOption(option);
    setSearchInputVal(option.name);
    setOptionsListIsOpen(false);
    setFocusSearchInput(false);
    handleCloseDropdown();
    searchInputElem.current?.blur();
  };

  const handleChangeSearchInput = (e) => {
    searchOptionsElem.current?.classList.remove('vc-d-none');
    setSearchInputVal(e.target.value);
  };

  const handleFocusSearchInput = () => {
    if (filteredOptions) {
      searchOptionsElem.current?.classList.remove('vc-d-none');
    }
    if (allowSearch) {
      setSearchInputVal('');
    }
    searchInputElem.current?.focus();
    setOptionsListIsOpen(true);
    setFocusSearchInput(true);
  };

  const toggleOptionList = () => {
    if (isSearchInputFocused) {
      setFocusSearchInput(false);
    }
    if (searchInputVal !== selectedOption.name) {
      setSearchInputVal(selectedOption.name);
    }
    setOptionsListIsOpen(!optionsListIsOpen);
  };

  useOnClickOutside(searchablDropdownRef, () => {
    setOptionsListIsOpen(false);
    setFocusSearchInput(false);
    setSearchInputVal(selectedOption.name);
  });

  useEffect(() => {
    onChangeOption(selectedOption);
    setSearchInputVal(selectedOption.name);
  }, [selectedOption.id, options]);

  useEffect(() => {
    const initialSelect = allowEmptyValue
      ? ((options.find((opt) => opt.id === initialVal) || {}) as SearchableDropdownOptionType)
      : options.find((opt) => opt.id === initialVal) || options[0];

    setSelectedOption(initialSelect);
  }, [initialVal]);

  return (
    <div
      className={styles.dropdown__container}
      ref={searchablDropdownRef}
      data-name={name}
    >
      <div className={styles['dropdown__input--container']}>
        {selectedValProps?.imgNode && !isSearchInputFocused ? selectedValProps?.imgNode(selectedOption) : ''}
        <input
          autoComplete="off"
          className={clsx(
            'vc-text-m',
            styles.dropdown__inputText,
            searchInputVal ? styles['dropdown__inputText--hasValue'] : '',
            isSearchInputFocused || optionsListIsOpen ? styles['dropdown__inputText--focused'] : '',
            !isSearchInputFocused && selectedValProps?.imgNode ? styles['dropdown__inputText--withImg'] : '',
          )}
          onChange={handleChangeSearchInput}
          onFocus={handleFocusSearchInput}
          onClick={() => setFocusSearchInput(true)}
          value={optionsListIsOpen ? searchInputVal : selectedOption.name}
          data-id={selectedOption.id}
          ref={searchInputElem}
          placeholder={inputPlaceholder || ''}
          readOnly={!allowSearch}
          name={name}
          aria-label={ariaLabel}
        />
        <div className={styles.dropdown__inputText__buttons__container}>
          <div className={styles.dropdown__inputText__icon__buttons}>
            <Button
              disableDefaultStyling
              className={clsx(
                styles.dropdown__inputText__icon__button,
                isSearchInputFocused && searchInputVal && allowSearch
                  ? styles['dropdown__inputText__icon__button--focused']
                  : styles['dropdown__inputText__icon__button--close'],
              )}
              onClick={() => {
                setSearchInputVal('');
                setOptionsListIsOpen(true);
              }}
            >
              <Icon name="close-default" />
            </Button>
            <Button
              disableDefaultStyling
              className={styles.dropdown__inputText__icon__button}
              onClick={toggleOptionList}
            >
              <Icon name={optionsListIsOpen ? 'small-dropdown-up-default' : 'small-dropdown-down-default'} />
            </Button>
          </div>
        </div>
      </div>
      <ul
        ref={searchOptionsElem}
        role="listbox"
        className={clsx(styles.dropdown__optionsList, optionsListIsOpen ? '' : 'vc-d-none')}
      >
        {optionsListIsOpen &&
          (filteredOptions.length ? (
            filteredOptions.map((option) => (
              <li
                key={option.id}
                data-id={option.id}
              >
                <div
                  className={clsx(
                    'vc-text-m',
                    styles.dropdown__optionsList__item,
                    option.name === selectedOption.name && styles['dropdown__optionsList__item--selected'],
                  )}
                  onClick={() => {
                    handleSelectOption(option);
                  }}
                  tabIndex={0}
                >
                  {renderOption ? (
                    renderOption(option, searchInputVal)
                  ) : (
                    <OptionWithHighlight
                      optionName={option.name}
                      searchTerm={searchInputVal}
                    />
                  )}
                  {option.name === selectedOption.name ? (
                    <Button
                      disableDefaultStyling
                      className={styles['dropdown__optionsList__item--selectedIcon']}
                    >
                      <Icon
                        className={styles.dropdown__optionsList__icon}
                        name="tick-small-default"
                      />
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </li>
            ))
          ) : (
            <li className={clsx('vc-text-m', styles['dropdown__optionsList__item--noResults'])}>
              <div>
                <span>{t('FAVORITE_PAGE.FILTER_SEARCH.RESULTS_EMPTY')}</span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

SearchableDropdown.displayName = 'SearchableDropdown';

export default SearchableDropdown;
