import { FCWithChildren, useEffect } from 'react';
import { Product } from '@interfaces/models/product';
import ProductCard from '@components/organisms/catalog/components/product-card/product-card';
import AuthGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import { useAuthentication } from '@context/authentication.context';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import usePageType from '@hooks/use-page-type';
import { SwiperSlide } from 'swiper/react';
import ProductSlider, { ProductSliderProps } from './product-slider';
import styles from './product-slider.module.scss';

interface VisibilityTrackerProps {
  isVisible: boolean;
  index: number;
  callback?: ({ isVisible, index }: { isVisible: boolean; index: number }) => void;
}

const VisibilityTracker: FCWithChildren<VisibilityTrackerProps> = ({ isVisible, index, callback, children }) => {
  useEffect(() => {
    callback?.({ isVisible, index });
  }, [isVisible]);
  return <>{children}</>;
};

type RegularProductsSlider = Omit<ProductSliderProps<Product>, 'children'> & {
  includeProductSchema?: boolean;
};

const RegularProductSlider = ({
  products,
  onVisibilityChange,
  showAuthGuard,
  parentBlockId,
  includeProductSchema,
  ...rest
}: RegularProductsSlider) => {
  const { setRedirectUrl } = useAuthentication();
  const { shouldDisplayAuthDialog, setShouldDisplayAuthDialog } = useAuthentication();
  const { pageType } = usePageType();
  const { setHpScrollPosition } = useRestoreHpScrollPosition();

  const handleClick = (product: Product) => {
    if (pageType === 'HomePage') {
      setHpScrollPosition(parentBlockId);
    }
    setShouldDisplayAuthDialog(false);
    setRedirectUrl({ url: product.path, mode: 'push' });
  };

  return (
    <ProductSlider
      products={products}
      {...rest}
    >
      {products.map((product, index) => (
        <SwiperSlide
          key={product.id}
          className={styles.product_slider__swiper_slide}
        >
          {({ isVisible }) => (
            <VisibilityTracker
              isVisible={isVisible}
              index={index}
              callback={onVisibilityChange}
            >
              <ConditionalWrapper
                condition={showAuthGuard && shouldDisplayAuthDialog}
                wrap={(children) => (
                  <AuthGuard
                    onClick={() => handleClick(product)}
                    shouldPreventDefault={true}
                    onAuth={() => (window.location.pathname = product.path)}
                  >
                    {children}
                  </AuthGuard>
                )}
              >
                <ProductCard
                  index={index}
                  product={product}
                  showDiscountPrice
                  showSellerBadge
                  showProductSize
                  showProductExpressDelivery
                  showProductLocation
                  showProductDirectShipping
                  showProductTags
                  includeProductSchema={includeProductSchema}
                  customClass={styles.product_slider__product_card}
                  {...rest.productCardProps}
                />
              </ConditionalWrapper>
            </VisibilityTracker>
          )}
        </SwiperSlide>
      ))}
    </ProductSlider>
  );
};

export default RegularProductSlider;
