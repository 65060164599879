import React from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import AuthenticationGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import { useTrackProductLikeAnalytics } from '@hooks/product/use-track-product-like-analytics';
import { useAuthentication } from '@context/authentication.context';
import useUserLikedProductsQuery from '@hooks/user/use-user-liked-products-query';
import { UseMutateFunction } from '@tanstack/react-query';
import styles from './product-like-button.module.scss';

const ProductLikeButton = (props) => {
  const {
    product,
    showText,
    customClass = '',
    testIdSuffix = '',
    isFavorite = false,
    position,
    parentPosition,
    pageType,
    moduleType,
    alertTracking,
    productFeedCategory,
  } = props;

  const { t } = useTranslation();
  const { setRedirectUrl } = useAuthentication();
  const {
    likedProducts = [],
    likeProduct,
    unlikeProduct,
  } = useUserLikedProductsQuery() as {
    likedProducts: string[];
    likeProduct: UseMutateFunction<void, Error, string, void>;
    unlikeProduct: UseMutateFunction<void, Error, string, void>;
  };
  const isProductLiked: boolean = likedProducts.includes(product.id);
  const productLikeCount: number = (product.likeCount ?? 0) + (likedProducts.includes(product.id) ? 1 : 0);

  const handleLike = (): void => {
    if (isProductLiked) {
      unlikeProduct(product.id);
    } else {
      likeProduct(product.id);
    }
  };

  const { trackOnLikeProduct, trackOnUnlikeProduct } = useTrackProductLikeAnalytics({
    product,
    position,
    parentPosition,
    pageType,
    moduleType,
    alertTracking,
    productFeedCategory,
  });

  const onClick = (): void => {
    if (isProductLiked) {
      trackOnUnlikeProduct();
    } else {
      trackOnLikeProduct();
    }
    handleLike();
  };

  return (
    <AuthenticationGuard
      onAuth={onClick}
      onClick={() => setRedirectUrl(null)}
    >
      <button
        type="submit"
        aria-label={`${t('LIKE.I_LIKE_ACCESSIBILITY')} ${product.name}`}
        className={clsx(
          customClass,
          styles.like__button,
          { [styles['like__button--textHidden']]: !showText },
          { [styles['like__button--favorite']]: isFavorite },
        )}
        data-cy={`favorite_button${testIdSuffix}`}
        data-vc-dd-action-name={`like_button`}
        onClick={onClick}
      >
        {/* Show count in front of the icon if we don't show text */}
        {!showText && <span data-cy={`product-like-button-like-count-${product.id}`}>{productLikeCount}</span>}

        {/* 'Like' icon */}
        <span
          className={clsx(styles.like__icon, isProductLiked && styles['like__icon--liked'])}
          data-cy={
            isProductLiked ? `favorite_button__liked${testIdSuffix}` : `favorite_button__like__icon${testIdSuffix}`
          }
        />

        {/* Text and count */}
        {showText && (
          <span
            data-cy={`product-like-button-like-text-${product.id}`}
            className={styles.like__text}
          >
            {t('LIKE.I_LIKE', { count: productLikeCount })}
          </span>
        )}
      </button>
    </AuthenticationGuard>
  );
};

export default ProductLikeButton;
