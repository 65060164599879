import Button from '@components/atoms/button/button';
import { AvailableCountryISOCodes, Country } from '@interfaces/models/country';
import { UserI18n } from '@interfaces/models/i18n';
import FormHoc, { FormChildrenProps } from '@components/molecules/form-hoc/form-hoc';
import Environment from '@config/index';
import { usePreferences } from '@context/preferences.context';
import PreferenceCookie from '@services/preferences-cookie-service';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import UserService from '@services/user-service';
import { AvailableLocales } from '@interfaces/models/locale';
import { AvailableCurrencies, Currency } from '@interfaces/models/currency';
import logger from '@helpers/utils/logger/client';
import { getSubdomainByLocale, getLanguageByLocale } from '@helpers/localization';
import Cookies from 'universal-cookie';
import useUser from '@hooks/user/use-user';
import { RegionId } from '@interfaces/models/region';
import { AppCookies } from '@enums/cookies';
import { useBucket } from '@context/bucket.context';
import { NEW_LANGUAGE_FF_MAPPING } from '@config/localization';
import useLocalizationQuery from '@hooks/localization/use-localization';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { useSiteConfirmation } from '@hooks/use-site-confirmation';
import useDisplayedPreference from '@hooks/localization/use-displayed-preference';
import useGetI18N from '@hooks/localization/use-get-i18n';
import SearchableDropdown, { OptionWithHighlight } from '@components/atoms/searchable-dropdown/searchable-dropdown';
import styles from './i18n.module.scss';

type I18nFormValues = {
  selectedLocale: AvailableLocales;
  selectedCountryISOCode: AvailableCountryISOCodes;
  selectedCurrency: Currency['isoCode'];
};

export enum I18nFormType {
  NEW_VISITOR = 'nv',
  FOOTER = 'footer',
}

export interface I18nFormProps {
  type: I18nFormType;
}

const I18nForm: React.FC<I18nFormProps> = ({ type }) => {
  const { localization: i18n } = useLocalizationQuery();

  const { t } = useTranslation('default-layout');
  const { isAuthenticated } = useUser();
  const isNvPopup = type === I18nFormType.NEW_VISITOR;
  const { currency, country, locale } = usePreferences();
  const i18nLanguage = i18n.language || {
    list: [],
    title: '',
  };
  const i18nCurrency = i18n.currency || {
    list: [],
    title: '',
  };
  const { isFeatureEnabled } = useBucket();
  const { hideLangPopup } = useSiteConfirmation();
  const { sendAnalyticEvent } = useAnalyticEvents('i18n_popup');

  const [cookies] = useState<Cookies>(() => new Cookies());
  const [selectedLocale, setSelectedLocale] = useState<AvailableLocales>(locale);
  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState<AvailableCountryISOCodes>(country);
  const countryInfo: Country = i18n.country.fullList.find(({ isoCode }) => isoCode === selectedCountryISOCode);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency['isoCode']>(currency);
  const selectedRegion = countryInfo?.idRegion;
  const { displayedCountry, displayedLang, displayedCurrency } = useDisplayedPreference();

  const refreshPage = (isoCode: AvailableLocales): void => {
    // On local env, because we only have 1 origin, replace doesn't work, so we have to manually reload for changes to take effect
    if (Environment.envName === 'dev') {
      window.location.reload();
      return;
    }
    const url = new URL(window.location.href);
    const newSubdomain = getSubdomainByLocale(isoCode, 'en-GB');
    const subdomains = url.host.split('.');
    subdomains[0] = newSubdomain;
    url.host = subdomains.join('.');
    window.location.replace(url.toString());
    if (window.location.hash) {
      // delete banner cookie on page reload
      const promoCookie = cookies.get(AppCookies.PDP_WELCOME_OFFER_CLOSED);
      if (promoCookie) {
        cookies.remove(AppCookies.PDP_WELCOME_OFFER_CLOSED, {
          path: '/',
          domain: Environment.cookieOptions.domain,
        });
      }
    }
    window.location.reload();
  };

  const onSubmit = async (): Promise<void> => {
    try {
      // Map user selected language locale to our supported language
      const language = getLanguageByLocale(selectedLocale);

      cookies.set(AppCookies.CK, `${countryInfo.idRegion}.${language}.${selectedCurrency}`, {
        path: '/',
        domain: Environment.cookieOptions.domain,
      });

      cookies.set(
        AppCookies.CC,
        PreferenceCookie.encode({
          CC: countryInfo.isoCode as AvailableCountryISOCodes,
          displayName: {},
        }),
        {
          path: '/',
          domain: Environment.cookieOptions.domain,
        },
      );

      hideLangPopup();

      if (isAuthenticated) {
        const userI18n = {
          country: countryInfo.isoCode,
          language: language,
          currency: selectedCurrency,
        } as UserI18n;

        await UserService.updateUserSettings(userI18n);
      }
      refreshPage(selectedLocale);
    } catch (e) {
      logger.info(e);
    }
  };

  const { i18n: newI18n, isLoading: isLoadingNewI18n } = useGetI18N({
    siteId: Number(selectedRegion) as RegionId,
    locale,
  });
  const data = isLoadingNewI18n ? i18n : newI18n;
  const updatedLanguageList = data?.language?.list || [];
  const updatedCurrencyList = data?.currency?.list || [];

  const setDefaultLanguageIfUnavailable = () => {
    const isCurrentLanguageAvailable = updatedLanguageList.map((lang) => lang.isoCode).includes(selectedLocale);
    if (!isCurrentLanguageAvailable && updatedLanguageList.length > 0) {
      setSelectedLocale(updatedLanguageList[0].isoCode);
    }
  };

  const setDefaultCurrencyIfUnavailable = () => {
    const currentLanguageAvailable = updatedCurrencyList.filter((currency) => currency.isoCode === selectedCurrency);

    if (!currentLanguageAvailable.length && updatedCurrencyList.length > 0) {
      setSelectedCurrency(updatedCurrencyList[0].isoCode);
    }
  };

  useEffect(() => {
    setDefaultLanguageIfUnavailable();
    setDefaultCurrencyIfUnavailable();
  }, [updatedLanguageList, updatedCurrencyList]);

  return (
    <div className={styles['vc-modal__innerContainer']}>
      <span
        className={styles.i18nChoices__modalTitle}
        data-cy="i18n_popup_title"
      >
        {t('I18N_MODAL.NEW_VISITOR.TITLE')}
      </span>
      <p className={clsx('vc-text-m', styles.i18nChoices__modalSubtitle)}>{t('I18N_MODAL.NEW_VISITOR.SUBTITLE')}</p>
      <FormHoc
        initialValues={{
          selectedCountryISOCode,
          selectedCurrency,
          selectedLocale,
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }: FormChildrenProps<I18nFormValues>) => {
          const isI18nValChanged =
            selectedLocale !== displayedLang?.isoCode ||
            selectedCountryISOCode !== displayedCountry?.isoCode ||
            selectedCurrency !== displayedCurrency?.isoCode;
          const countryLists = i18n.country.fullList.map((country) => {
            return {
              id: country.isoCode,
              name: country.name,
            };
          });

          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.i18nChoices__gridContainer}>
                <div className={clsx(styles.i18nChoices__modalCol, styles.i18n__modalCol)}>
                  <label className={styles.i18nChoices__select__label}>{data.country.title}</label>
                  <div className="vc__select">
                    <SearchableDropdown
                      name="i18n-country"
                      aria-label={t('I18N.MODAL.SELECT.COUNTRY')}
                      initialVal={selectedCountryISOCode}
                      selectedValProps={{
                        imgNode: (selectedOption) => (
                          <img
                            src={`${Environment.apiStaticBaseUrl}/flags/${selectedOption.id
                              .toString()
                              .toLowerCase()}.svg`}
                            alt={selectedOption.name}
                            className={styles.i18nChoices__country__selectedFlag}
                          />
                        ),
                      }}
                      options={countryLists}
                      renderOption={(option, searchVal) => (
                        <div className={styles.i18nChoices__country__optionItem}>
                          <img
                            alt={option.name}
                            className={styles.i18nChoices__country__optionFlag}
                            src={`${Environment.apiStaticBaseUrl}/flags/${option.id.toString().toLowerCase()}.svg`}
                          />
                          <OptionWithHighlight
                            optionName={option.name}
                            searchTerm={searchVal}
                          />
                        </div>
                      )}
                      inputPlaceholder={i18n.country.title}
                      onChangeOption={(selectedOption) => {
                        setSelectedCountryISOCode(selectedOption.id as AvailableCountryISOCodes);
                      }}
                    />
                  </div>
                </div>
                <div className={clsx(styles.i18nChoices__modalCol, styles.i18n__modalCol)}>
                  <label className={styles.i18nChoices__select__label}>{i18nLanguage.title}</label>
                  <div className="vc__select">
                    <SearchableDropdown
                      name="i18n-language"
                      aria-label={t('I18N.MODAL.SELECT.LANGUAGE')}
                      initialVal={selectedLocale}
                      allowSearch={false}
                      options={(
                        updatedLanguageList.map((lang) => {
                          if (NEW_LANGUAGE_FF_MAPPING && NEW_LANGUAGE_FF_MAPPING[lang.isoCode]) {
                            const ffName = NEW_LANGUAGE_FF_MAPPING[lang.isoCode];
                            const isLanguageEnabled = isFeatureEnabled((f) => !!f[ffName], false) || false;

                            if (isLanguageEnabled) {
                              return {
                                id: lang.isoCode,
                                name: lang.name,
                              };
                            }

                            return null;
                          } else {
                            return {
                              id: lang.isoCode,
                              name: lang.name,
                            };
                          }
                        }) || []
                      ).filter((opt) => opt)}
                      inputPlaceholder={i18nLanguage.title}
                      onChangeOption={(selectedOption) => {
                        setSelectedLocale(selectedOption.id as AvailableLocales);
                      }}
                    />
                  </div>
                </div>
                <div className={clsx(styles.i18nChoices__modalCol, styles.i18n__modalCol)}>
                  <label className={styles.i18nChoices__select__label}>{i18nCurrency.title}</label>
                  <div className="vc__select">
                    <SearchableDropdown
                      name="i18n-currency"
                      aria-label={t('I18N.MODAL.SELECT.CURRENCY')}
                      initialVal={selectedCurrency}
                      allowSearch={false}
                      options={updatedCurrencyList.map((currency) => {
                        return {
                          id: currency.isoCode,
                          name: `${currency.symbol} ${currency.isoCode}`,
                        };
                      })}
                      inputPlaceholder={i18nCurrency.title}
                      onChangeOption={(selectedOption) => {
                        setSelectedCurrency(selectedOption.id as AvailableCurrencies);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['col-12']}>
                <div className={styles.i18nChoices__modalAction}>
                  <Button
                    data-cy="submit_i18n"
                    type="submit"
                    variant="primary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      if (isNvPopup) {
                        if (isI18nValChanged) {
                          sendAnalyticEvent('confirm_i18n_saveChange', {
                            property: JSON.stringify({
                              country: selectedCountryISOCode,
                              currency: selectedCurrency,
                              language: selectedLocale,
                            }),
                          });
                        } else {
                          sendAnalyticEvent('confirm_i18n_continue', {
                            property: JSON.stringify({
                              country: displayedCountry.isoCode,
                              currency: displayedCurrency.isoCode,
                              language: displayedLang.isoCode,
                            }),
                          });
                        }
                      }
                    }}
                  >
                    {isI18nValChanged
                      ? t('I18N_MODAL.NEW_VISITOR.SAVE_CHANGE')
                      : t('I18N_MODAL.NEW_VISITOR.SAVE_BUTTON')}
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </FormHoc>
    </div>
  );
};

export default I18nForm;
