import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Button from '@components/atoms/button/button';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import style from './editing.module.scss';

interface UniverseTabProps {
  value: number;
  onChange: (val: number) => void;
}

const data = ['PERSO.EDIT_FILTER_WOMEN.CTA', 'PERSO.EDIT_FILTER_MEN.CTA', 'PERSO.EDIT_FILTER_BOTH.CTA'];

const UniverseTab: React.FC<UniverseTabProps> = (props) => {
  const { value, onChange } = props;

  const valRef = useRef<number>(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleUniverseChange = (val: number) => {
    valRef.current = val;
    if (val === value) {
      return;
    }
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    onChange(valRef.current);
  };

  return (
    <div>
      <div className={style.sizeTypeTitle}>{t('PERSO.FILTER_NOT_SET.TITLE')}</div>
      <div className={style.btnlist}>
        {data.map((item, index) => (
          <div
            className={style.buttonWrap}
            key={index}
          >
            <Button
              onClick={() => {
                handleUniverseChange(index);
              }}
              className={clsx(style.universeTab, value === index ? style.checked : style.unchecked)}
              variant="outline"
              size="medium"
            >
              {t(item)}
            </Button>
          </div>
        ))}
      </div>

      <ConfirmDialog
        setOpen={setOpen}
        open={open}
        title={t('PLP.MYSIZES_DIALOG.CHANGE_CATEGORY.TITLE')}
        content={t('PLP.MYSIZES_DIALOG.CHANGE_CATEGORY.CONTENT')}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default UniverseTab;
