import Environment from '@config/index';

type CookieName =
  | 'CC'
  | 'CK'
  | 'SESSION'
  | 'UID'
  | 'ANONYMOUS_USER_ID_SSR'
  | 'ANONYMOUS_USER_ID_CSR'
  | 'RANDOM_RANGE_ID'
  | 'SMART_BANNER_CLOSED'
  | 'USER_CHAT_CHAT_ONBOARDING_DONE'
  | 'NV_SHOW_LANG_POPIN'
  | 'PDP_WELCOME_OFFER_CLOSED';

type NamedCookies = Record<CookieName, string>;

export const AppCookieOptions = Environment.cookieOptions;

export const AppCookies: NamedCookies = {
  // Cookie that indicates localization. It's constructed with {regionId (siteId in the code)}.{regionName}.{currency}
  CK: 'vc_ck',
  // B64 encoded cookie that gives us the selected country's display name
  CC: 'vc_cc',
  // Logged in user's session id
  SESSION: Environment.cookieSession,
  // User id
  UID: Environment.cookieUID,
  // UUID set on server, used for LD
  ANONYMOUS_USER_ID_SSR: Environment.cookieAnonymousUserSsr,
  // UUID set on client, used for LD. Effectively the same as ANONYMOUS_USER_ID_SSR, but it being set on the client apparently allows LD to pick it up easier
  // https://vestiairecollective.atlassian.net/browse/TAC-1035
  ANONYMOUS_USER_ID_CSR: Environment.cookieAnonymousUser,
  // LDs plan is limited to 1M unique users. In case user is not logged in, we assign a random id from [0, 1M]. Some anonymous users may therefore have the same id, but that's fine
  RANDOM_RANGE_ID: Environment.randomRangeId,
  // cookie that indicates if a user has closed smart banner
  SMART_BANNER_CLOSED: 'smart_banner_closed',
  // cookie used to know if the user has seen the onboarding chat popup
  USER_CHAT_CHAT_ONBOARDING_DONE: 'user_chat_onboarding_done',
  NV_SHOW_LANG_POPIN: 'vc_show_lang_popin',
  PDP_WELCOME_OFFER_CLOSED: 'welcomeOfferBannerClosed',
};
