import { CatalogProduct } from '@interfaces/models/catalogProduct';
import { Product } from '@interfaces/models/product';
import { getOrCreateLDCookies } from '@components/bucketTest/bucket.utils';

import { DataLayerInterface } from '@interfaces/models/dataLayerInterface';
import { ElasticSearch } from '@interfaces/models/elasticSearch';

export type CatalogDatalayerType = Pick<
  DataLayerInterface,
  | 'catalog_version'
  | 'index_name'
  | 'nbHits'
  | 'perso_available'
  | 'perso_created'
  | 'perso_enabled'
  | 'perso_results_nb'
  | 'perso_results_returned'
  | 'list_products'
  | 'search_query_id'
  | 'filters_details'
  | 'catalog_brand'
  | 'catalog_universe'
  | 'catalog_category'
  | 'catalog_subcategory'
  | 'nb_results'
  | 'filters_applied'
  | 'are_filters_active'
>;

export const pickSearchContextAttributes = (
  attributes: (keyof DataLayerInterface)[],
): Partial<CatalogDatalayerType> => {
  if (typeof window === 'undefined') {
    return {};
  }
  const dataLayer = window.tc_vars;
  if (!dataLayer) {
    return {};
  }
  return attributes.reduce((acc, attribute) => {
    acc[attribute] = dataLayer[attribute];
    return acc;
  }, {});
};

export const RESET_CATALOG_DATALAYER: Partial<DataLayerInterface> = {
  keyword: '',
  keyword_user_input: '',
  keyword_autocorrected: '',
  campaign_id: '',
  onsite_campaign_id: '',
  filtered_campaign_id: '',
  pageUrl: '',
};

export const getProductTrackingData = (product: CatalogProduct | Product) => ({
  product_id: String(product.id),
  seller_id: product.seller?.id ? product.seller?.id : '',
  product_universe: 'universeId' in product ? String(product.universeId) : '',
  product_category: 'category' in product ? String(product.category.localizedName) : '',
  product_sub_category: 'subcategory' in product ? String(product.subcategory.localizedName) : '',
  product_brand: product.brand?.name,
  product_unitprice_ati: String((product.price?.cents ?? 0) / 100 ?? ''),
  product_country: product.isoCountry ?? '',
  product_card_tag: 'isNewProduct' in product && product.isNewProduct ? 'NEW' : product.tags?.join(',') ?? '',
  currency: product.price?.currency ?? '',
});

export const getCatalogTrackingData = () =>
  pickSearchContextAttributes([
    'keyword',
    'index_name',
    'catalog_brand',
    'catalog_universe',
    'catalog_category',
    'catalog_subcategory',
    'campaign_id',
    'cms_block_id',
    'filters_applied',
    'nb_results',
    'filters_details',
    'search_query_id',
  ]);

export const getUserToken = (userId: string | undefined) => {
  const { anonymousId } = getOrCreateLDCookies();
  return userId || `anonymous-${anonymousId}`;
};

export const getCatalogDatalayerEntrypoint = ({
  source_category,
  source_subcategory,
  cms_block_id,
}: {
  source_category?: string;
  source_subcategory?: string;
  cms_block_id?: string;
}) => {
  return {
    source_category: source_category ?? '',
    source_subcategory: source_subcategory ?? '',
    cms_block_id: cms_block_id ?? '',
  };
};

export const getApplyFilterPayload = (
  filters: ElasticSearch['filters'],
  filters_applied: { [key: string]: string },
) => {
  return {
    are_filters_active: 'true',
    filters_applied: JSON.stringify(filters_applied),
    filters_details: JSON.stringify(filters),
    ...pickSearchContextAttributes([
      'keyword',
      'index_name',
      'catalog_brand',
      'catalog_universe',
      'catalog_category',
      'catalog_subcategory',
      'nb_results',
      'search_query_id',
      'search_session_id',
    ]),
  };
};
