import { useQuery } from '@tanstack/react-query';
import { NotificationQueryKeys } from '@enums/react-query-keys';
import { NotificationFeedCounterResponseV2 } from '@interfaces/api/responses/notification-feed-counter-response';
import { getNotificationCountV2, resetNotificationsFeedCountersV2 } from '@api/notifications';
import useUser from '@hooks/user/use-user';
import logger from '@helpers/utils/logger/client';

const DEFAULT_NOTIFICATION_FEED_COUNTERS = {
  all: 0,
  messages: 0,
  updates: 0,
};

const useNotificationCountQueryV2 = () => {
  const { user, isAuthenticated } = useUser();

  const { data: notificationFeedCounters, refetch: refetchNotificationCount } = useQuery<
    NotificationFeedCounterResponseV2['unseen']
  >({
    queryKey: [NotificationQueryKeys.NOTIFICATION_FEED_COUNTER_V2, user?.id],
    queryFn: () => getNotificationCountV2(),
    refetchInterval: 90000,
    refetchIntervalInBackground: true,
    enabled: isAuthenticated,
    initialData: DEFAULT_NOTIFICATION_FEED_COUNTERS,
  });

  const notificationCount: number = notificationFeedCounters.all;

  // Whenever notification popin closes, we need reset / update the feed counters
  const resetAndUpdateNotificationFeed = async (): Promise<void> => {
    try {
      await resetNotificationsFeedCountersV2();
      await refetchNotificationCount();
    } catch (error) {
      logger.error('Error during resetting notification feed counters:', error);
    }
  };

  return {
    notificationFeedCounters,
    notificationCount,
    resetAndUpdateNotificationFeed,
    refetchNotificationCount,
  };
};

export default useNotificationCountQueryV2;
