import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import Icon from '../icon/icon';
import styles from './button.module.scss';

export type ButtonVariant =
  | 'none'
  | 'primary'
  | 'primary-white'
  | 'secondary'
  | 'outline'
  | 'ghost'
  | 'social'
  | 'chip'
  | 'rounded-secondary';

type ButtonSize = 'none' | 'small' | 'medium' | 'large';

type ButtonNotFullWidthQuery = 'sm-down' | 'sm-up' | 'md-up' | 'lg-up';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  notFullWidthQuery?: ButtonNotFullWidthQuery;
  disableDefaultStyling?: boolean;
  iconLeft?: string;
  iconRight?: string;
  withLoader?: boolean;
  loaded?: boolean;
};

const Button: React.FC<ButtonProps> = (props) => {
  const {
    type = 'button',
    variant = 'none',
    size = 'none',
    fullWidth = false,
    notFullWidthQuery,
    disableDefaultStyling = false,
    iconLeft,
    iconRight,
    withLoader = false,
    loaded = false,
    children,
    className,
    ...buttonProps
  } = props;

  return (
    <button
      type={type}
      className={clsx(
        !disableDefaultStyling && 'vc-btn',
        variant !== 'none' && `vc-btn--${variant}`,
        size !== 'none' && `vc-btn--${size}`,
        fullWidth && `vc-btn--full`,
        fullWidth && notFullWidthQuery && styles[`button--notfull--${notFullWidthQuery}`],
        Boolean(iconLeft || iconRight) && `vc-btn--with-icon`,
        withLoader && `vc-btn--with-loader`,
        loaded && `vc-btn--with-loader--loaded`,
        className,
      )}
      {...buttonProps}
    >
      {withLoader ? (
        <>
          <span className="vc-btn--with-loader__text">{children}</span>
          <span className="vc-btn--with-loader__spinner"></span>
        </>
      ) : (
        <>
          {iconLeft && (
            <Icon
              className="btnIcon"
              name={iconLeft}
            />
          )}
          {children}
          {iconRight && (
            <Icon
              className="btnIcon"
              name={iconRight}
            />
          )}
        </>
      )}
    </button>
  );
};

export default Button;
