import { FC, MouseEvent, JSX } from 'react';
import { useContextSelector } from 'use-context-selector';

import { CMSField as FieldProps, CMSFieldType as FieldType } from '@interfaces/models/cms/campaign-hero';
import Icon from '@components/atoms/icon/icon';
import { ModalContext } from '@context/modal.context';
import { useTranslation } from 'next-i18next';
import { useFieldStylesAndClasses } from '../cms-block-fields';
import styles from '../cms-block-fields.module.scss';
import ContextualModal from './cms-contextual-modal/cms-contextual-modal';

export const IconField: FC<{
  data: FieldProps['data'];
  onClick?: (e: MouseEvent | KeyboardEvent, data: FieldProps['data']) => void;
  onCustomEvent?: (eventName: string, prefix: string, data) => void;
}> = ({ data, onClick, onCustomEvent }): JSX.Element => {
  const { iconName, color, contextualRedirection } = data;
  const { fieldStyles, fieldClass } = useFieldStylesAndClasses(FieldType.Icon, data);
  const openModal = useContextSelector(ModalContext, (v) => v.openModal);
  const { t } = useTranslation('default-layout');

  const trackPopinEvent = (action: string) => {
    onCustomEvent?.('popin_voucher_info', 'pop_in_', { action, label: contextualRedirection.trackingLabel });
  };

  const openContextualModal = () =>
    openModal(
      <ContextualModal
        data={contextualRedirection}
        onTrackCTAClick={trackPopinEvent}
      />,
      {
        customClass: styles.contextualModal,
        fullScreenOnMobile: false,
        onAfterOpen: () => trackPopinEvent('view'),
        onAfterClose: () => trackPopinEvent('close'),
        styles: {
          content: {
            width: '85%',
            maxWidth: '600px',
          },
        },
      },
    );

  const handleOnClick = (e: MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick?.(e as MouseEvent, data);
    openContextualModal();
  };
  return (
    <button
      type="button"
      className={fieldClass}
      style={fieldStyles}
      onClick={handleOnClick}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
          handleOnClick(e);
        }
      }}
    >
      <Icon
        className="d-block"
        style={{ color }}
        name={`${iconName}-large`}
      />
      <span className="visuallyhidden">{t('MODAL.BUTTON.MORE_INFO')}</span>
    </button>
  );
};
