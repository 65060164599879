import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Button from '@components/atoms/button/button';
import Cart from '@components/atoms/cart/cart';
import Icon from '@components/atoms/icon/icon';
import Logo from '@components/atoms/logo/logo';
import SearchBar from '@components/atoms/search-bar/search-bar';
import MainNavigation from '@components/molecules/main-nav/main-navigation';
import TopNavigation from '@components/top-navigation/top-navigation';
import { SearchProvider } from '@context/search.context';
import SmartBannerWrapper from '@components/molecules/smart-banner/smart-banner-wrapper';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import useHeaderQuery from '@hooks/header/use-header-query';
import useUser from '@hooks/user/use-user';
import dynamic from 'next/dynamic';
import { HeaderDataItem } from '@interfaces/models/header';
import styles from './header.module.scss';

const MobileNavigation = dynamic(() => import('@components/molecules/mobile-navigation/mobile-navigation'), {
  ssr: false,
});

type HeaderProps = {
  shouldShowBanner?: boolean;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { shouldShowBanner } = props;
  const { t } = useTranslation('default-layout');
  const { isAuthenticated } = useUser();
  const { sendAnalyticEvent } = useAnalyticEvents('navigation');

  // This state is used to determine when to load full header data
  const [hasEnteredNavigation, setHasEnteredNavigation] = useState<boolean>(false);
  const [isMobileNavigationOpened, setIsMobileNavigationOpened] = useState<boolean>(false);

  const shouldLoadFullHeaderData = isMobileNavigationOpened || hasEnteredNavigation;

  const { headerData } = useHeaderQuery(shouldLoadFullHeaderData);

  const onMobileNavigationOpen = () => {
    setIsMobileNavigationOpened(true);
    sendAnalyticEvent('open_navigation_tab', {});
  };

  const initiateHeaderLoad = () => {
    if (!hasEnteredNavigation) {
      setHasEnteredNavigation(true);
    }
  };

  return (
    <SearchProvider>
      <SmartBannerWrapper />
      <header
        className={clsx(
          styles.mainHeader,
          shouldShowBanner && styles.withBanner,
          isMobileNavigationOpened && styles['mainHeader--navOpen'],
        )}
        id="header"
        onMouseEnter={initiateHeaderLoad}
        onFocus={initiateHeaderLoad}
      >
        {/* Main line includes search bar, logo, user panel and cart */}
        <div className={styles.mainHeader__mainLine}>
          <div
            className={clsx(
              styles.mainHeader__innerWrapper,
              styles['mainHeader__innerWrapper--mainLine'],
              'innerContainer',
            )}
          >
            {/* Search bar in header for desktop users only, not rendered when mobile navigation is open to prevent duplicate elements */}
            {!isMobileNavigationOpened && <SearchBar className="d-none d-lg-flex" />}
            {/* Mobile menu button visible on mobile and tablets */}
            <Button
              aria-label={t('HEADER.NAVIGATION.BUY_TITLE')}
              tabIndex={0}
              variant="outline"
              size="none"
              className={styles.mainHeader__mobileNavigationButton}
              onClick={onMobileNavigationOpen}
              data-cy="search_button_mobile"
              data-vc-dd-action-name="search_on_mobile"
            >
              <Icon
                className={styles.mainHeader__mobileNavigationButtonIcon}
                name="menu-search"
              />
            </Button>

            <Logo />

            {/* User panel & shopping cart */}
            <div
              className={clsx(
                styles.mainHeader__userPanelAndCart,
                isAuthenticated && styles['mainHeader__userPanelAndCart--isAuthenticated'],
              )}
            >
              <TopNavigation />
              <Cart />
            </div>
          </div>
        </div>

        {/* Second line includes product categories */}
        <div className={clsx(styles.mainHeader__secondLine, 'd-none d-lg-flex innerContainer')}>
          <div className={clsx(styles.mainHeader__innerWrapper, styles['mainHeader__innerWrapper--secondLine'])}>
            <MainNavigation headerContent={headerData as HeaderDataItem[]} />
          </div>
        </div>

        {/* Mobile navigation */}
        {isMobileNavigationOpened && (
          <MobileNavigation
            setIsOpened={setIsMobileNavigationOpened}
            headerContent={headerData as HeaderDataItem[]}
          />
        )}
      </header>
    </SearchProvider>
  );
};

export default React.memo(Header);
