import React, { MouseEvent, KeyboardEvent, FC, HTMLProps } from 'react';
import clsx from 'clsx';
import Link from '@components/atoms/link/link';
import sanitizer from '@helpers/sanitizer';
import styles from './chip.module.scss';
export interface ChipFieldProps {
  displayTitle: string;
  title?: string;
  url: string;
  textColor?: string;
  uid?: string;
  contentTypeUid?: string;
}
interface ChipProps extends HTMLProps<HTMLAnchorElement> {
  chip: ChipFieldProps;
  onChipClick?: (e: MouseEvent | KeyboardEvent, chip: ChipFieldProps) => void;
  isHtml?: boolean;
  dataCyIdentifier?: string;
}

const Chip: FC<ChipProps> = (props): React.ReactElement => {
  const { chip, className, onChipClick, style, isHtml, dataCyIdentifier = 'chipsField-chip' } = props;
  const { displayTitle, url } = chip;
  const handleClick = (e: MouseEvent | KeyboardEvent) => {
    onChipClick?.(e, chip);
  };
  return (
    <Link
      href={url}
      className={clsx(styles.chip, 'vc-btn--rounded-secondary', className)}
      style={style}
      data-cy={dataCyIdentifier}
      onClick={handleClick}
    >
      {isHtml ? <span dangerouslySetInnerHTML={{ __html: sanitizer(displayTitle) }} /> : <span>{displayTitle}</span>}
    </Link>
  );
};

export default Chip;
