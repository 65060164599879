import Icon from '@components/atoms/icon/icon';
import useOnClickOutside from '@hooks/browser-events/use-on-click-outside';
import clsx from 'clsx';
import Button from '@components/atoms/button/button';
import React, { Dispatch, SetStateAction, useRef, Suspense } from 'react';
import { useTranslation } from 'next-i18next';
import { useContextSelector } from 'use-context-selector';
import { SearchContext } from '@context/search.context';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import useWindowSize from '@hooks/use-window-size';
import dynamic from 'next/dynamic';
import { useAuthentication } from '@context/authentication.context';
import { SearchBarRecentSearchesContext } from '@context/search-bar-recent-searches.context';
import { ContentIds } from '@interfaces/models/searchBarContentIds';
import { useBucket } from '@context/bucket.context';
import { FocusTrap } from 'focus-trap-react';
import styles from '../search-bar.module.scss';
import ClearInputButton from './clear-button';

const CloseButton = dynamic(() => import('@components/atoms/close-button/close-button'), {
  ssr: false,
});
const SearchBarRecentSearches = dynamic(
  () => import('@components/atoms/search-bar/components/search-bar-recent-searches/search-bar-recent-searches'),
  { ssr: false, loading: () => <div className={styles.searchbar__recentSearchPlaceholder} /> },
);
const SearchBarSuggestions = dynamic(() => import('./search-bar-suggestions'), {
  ssr: false,
  loading: () => <div className={styles.searchbar__suggestionsPlaceholder} />,
});
const FastFashionBanner = dynamic(
  () => import('@components/atoms/search-bar/components/fast-fashion-banner/fast-fashion-banner'),
  { ssr: false },
);
const SaveSearchErrorMessage = dynamic(
  () => import('@components/atoms/search-bar/components/search-bar-recent-searches/save-search-error-message'),
  { ssr: false },
);

const ImageSearchIcon = dynamic(
  () => import('@components/atoms/search-bar/components/image-search/image-search-icon/image-search-icon'),
  { ssr: false },
);

interface SearchModalProps {
  setIsSearchHeaderOpen: (value: React.SetStateAction<boolean>) => void;
  handleClick?: Dispatch<SetStateAction<boolean>>;
  showFastFashionBanner: boolean;
  setShowFastFashionBanner: Dispatch<SetStateAction<boolean>>;
}

const SearchModal: React.FC<SearchModalProps> = (props) => {
  const { setIsSearchHeaderOpen, handleClick, showFastFashionBanner, setShowFastFashionBanner } = props;

  const { t } = useTranslation('default-layout');
  const { isAuthOpen } = useAuthentication();
  const { isWiderThanLg } = useWindowSize();
  const { isFeatureEnabled } = useBucket();
  const searchQuery = useContextSelector(SearchContext, (v) => v.searchQuery);
  const setSearchQuery = useContextSelector(SearchContext, (v) => v.setSearchQuery);
  const popularSearches = useContextSelector(SearchContext, (v) => v.popularSearches);
  const brands = useContextSelector(SearchContext, (v) => v.brands);
  const suggestions = useContextSelector(SearchContext, (v) => v.suggestions);
  const recentSearches = useContextSelector(SearchBarRecentSearchesContext, (v) => v.recentSearches);
  const errorMessage = useContextSelector(SearchBarRecentSearchesContext, (v) => v.errorMessage);
  const isImageSearchEnabled = isFeatureEnabled((f) => f.enableImageSearch, true);

  const container = useRef<HTMLDivElement>(null);

  useOnClickOutside(container, () => {
    // if auth modal is open we keep search modal open while the user is logging in
    if (isWiderThanLg && !isAuthOpen) {
      setIsSearchHeaderOpen(false);
    }
  });

  const results = [
    // Recent searches are visible only before client starts typing
    // { queryResults: recentSearches, contentId: "recent", visible: searchQuery === '' && recentSearches.length > 0 },
    // Popular searches are visible only before client starts typing
    {
      queryResults: popularSearches,
      contentId: ContentIds.Popular,
      visible: searchQuery === '' && popularSearches.length > 0,
    },
    // Suggestions appear once client starts typing
    {
      queryResults: suggestions,
      contentId: ContentIds.ProductSuggestion,
      visible: searchQuery !== '',
    },
    // Brands appear once client starts typing and search results are not empty
    {
      queryResults: brands,
      contentId: ContentIds.BrandSuggestion,
      visible: searchQuery !== '',
    },
  ];

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const showRecentSearches = !searchQuery && recentSearches.length > 0;

  return (
    <ConditionalWrapper
      condition={isWiderThanLg && !isAuthOpen}
      wrap={(children) => (
        <Suspense fallback={children}>
          <FocusTrap>{children}</FocusTrap>
        </Suspense>
      )}
    >
      <div
        ref={container}
        className={clsx(
          styles.searchbar__modal,
          styles['searchbar__modal--visible'],
          styles['searchbar__modal--fullWidth'],
        )}
      >
        <div className={styles.searchbar__innerWrapper}>
          <div className={styles.searchbar__inputWrapper}>
            {/* Show search icon only on large devices */}
            {isWiderThanLg && (
              <Icon
                className={styles.searchbar__modalButtonIcon}
                name="search"
              />
            )}
            {/* Show back button only on mobile */}
            {!isWiderThanLg && (
              <Button
                disableDefaultStyling
                className={styles.searchbar__backButton}
                onClick={() => {
                  setIsSearchHeaderOpen(false);
                  handleClick?.(false);
                }}
              >
                <span className={styles.searchbar__backButtonIcon} />
              </Button>
            )}
            <input
              // eslint-disable-next-line -- business need
              autoFocus
              autoComplete="off"
              placeholder={t('HEADER.SEARCH.INPUT_PLACEHOLDER')}
              id="searchbar__input"
              className={styles.searchbar__input}
              type="text"
              onChange={onChange}
              data-cy="search_input"
              value={searchQuery}
            />
            <label htmlFor="searchbar__input">
              <span
                hidden
                className="hide"
              >
                {t('HEADER.SEARCH.INPUT_PLACEHOLDER')}
              </span>
            </label>
            {searchQuery !== '' && (
              <ClearInputButton
                className={clsx(styles.searchbar__clearButton, styles['searchbar__clearButton--open'])}
              />
            )}
            {isImageSearchEnabled && <ImageSearchIcon />}
          </div>
          {isWiderThanLg && (
            <CloseButton
              handleClick={() => setIsSearchHeaderOpen(false)}
              customClass={styles.searchbar__closeButton}
            />
          )}
          <div className={styles.searchbar__suggestions}>
            {!isWiderThanLg && errorMessage && <SaveSearchErrorMessage isMobileView={true} />}
            {!isWiderThanLg && showFastFashionBanner && (
              <FastFashionBanner handleClose={() => setShowFastFashionBanner(false)} />
            )}
            {showRecentSearches && <SearchBarRecentSearches setShowFastFashionBanner={setShowFastFashionBanner} />}
            {results.map((result) => (
              <React.Fragment key={result.contentId}>
                {result.visible && (
                  <SearchBarSuggestions
                    queryResults={result.queryResults}
                    contentId={result.contentId}
                    setShowFastFashionBanner={setShowFastFashionBanner}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
          {isWiderThanLg && showFastFashionBanner && (
            <FastFashionBanner handleClose={() => setShowFastFashionBanner(false)} />
          )}
          {isWiderThanLg && errorMessage && <SaveSearchErrorMessage isMobileView={false} />}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default SearchModal;
