import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from '@interfaces/models/user';
import { UserQueryKeys } from '@enums/react-query-keys';
import Cookies from 'universal-cookie';
import { AppCookieOptions, AppCookies } from '@enums/cookies';
import UserService from '@services/user-service';
import { useAnalytics } from '@context/analytics.context';
import { USER_INITIAL_STATE } from '@helpers/utils/analytics';
import usePageType from '@hooks/use-page-type';

const useUser = () => {
  const queryClient = useQueryClient();
  const { updateDataLayer } = useAnalytics();
  const { pageType } = usePageType();
  const { data: user } = useQuery<User | null>({
    queryKey: [UserQueryKeys.USER],
    staleTime: Infinity,
    queryFn: () => {
      const cookies = new Cookies();
      return UserService.getUser(cookies.get(AppCookies.UID));
    },
    initialData: null,
  });

  const { mutate: logout } = useMutation<void, Error, void>({
    mutationFn: async () => {
      const cookies = new Cookies();
      queryClient.setQueryData<null>([UserQueryKeys.USER], null);
      queryClient.setQueryData<null>([UserQueryKeys.USER_SIZES], null);
      localStorage.removeItem('userLikes');
      localStorage.removeItem('dataLayer');
      cookies.remove(AppCookies.SESSION, AppCookieOptions);
      cookies.remove(AppCookies.UID, AppCookieOptions);
      updateDataLayer({
        ...USER_INITIAL_STATE,
      });
    },
    networkMode: 'always',
    onSuccess: () => {
      // reload the homepage when user logged out
      if (pageType && pageType === 'HomePage' && typeof window !== 'undefined') {
        window.location.reload();
      }
    },
  });
  async function invalidateUserCache() {
    return queryClient.invalidateQueries({
      queryKey: [UserQueryKeys.USER],
    });
  }

  const isAuthenticated = !!user;
  const userId = user?.id ?? '';

  return {
    user,
    isAuthenticated,
    userId,
    logout,
    invalidateUserCache,
  };
};

export default useUser;
